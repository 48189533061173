@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    font-family: 'Montserrat', sans-serif;
}
.welcome-background {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url('./assets/img/welcome-background.jpg'); */
    background-position: center -150px;
}
.welcome-background .welcome-text-container {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #212627;
}
.welcome-background .welcome-text-container .border-welcome {
    position: absolute;
    top: -139px;
    right: 0;
    width: 100%;
}
.welcome-background .welcome-text-container .border-welcome img{
    width: 100%;
    height: 140px;
}
.welcome-background .welcome-text-container .welcome-content {
    max-width: 420px;
    margin: auto;
    padding: 30px;
    padding-top: 0;
    text-align: center;
}
.welcome-background .welcome-text-container .welcome-content h2 {
    color: #fff;
    font-family: 'Lora', serif;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 1px;
}
.welcome-background .welcome-text-container .welcome-content h5 {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    margin-bottom: 60px;
}
.welcome-background .welcome-text-container .welcome-content .btn {
    padding: 10px 20px;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 24px;
}
.welcome-background .welcome-text-container .welcome-content .btn-primary {
    color: #fff;
    background-color: #FEB62C;
    border-color: #FEB62C;
}
.welcome-background .welcome-text-container .welcome-content .btn-primary:focus {
    box-shadow: none;
}
.welcome-background .welcome-text-container .welcome-content .back-link {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
}
.welcome-background .welcome-text-container .welcome-content .back-link:hover {
    text-decoration: none;
}
.menu-header {
    position: relative;
    z-index: 0;
    padding: 16px;
    background-size: cover;
    background-position: center;
}
.menu-header:after {
    content: '';
    background: radial-gradient(67.71% 67.71% at 81.73% 28.54%, rgba(1, 1, 1, 0) 0%, rgba(1, 1, 1, 0.7) 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.menu-header .text-container {
    z-index: 1;
}
.menu-header .text-container h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #F2F2F2;
    margin-bottom: 8px;
}
.menu-header .text-container h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #F2F2F2;
    margin-bottom: 8px;
}
.menu-header .text-container p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #F2F2F2;
    margin-bottom: 8px;
}
.menu-header .back-btn {
    z-index: 1;
    margin-bottom: 18px;
    display: inline-block;
}
.menu-list {
    display: flex;
    flex-wrap: wrap;
    padding: 22px 8px;
}
.menu-list .menu-item {
    width: calc(50% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    height: 200px;
    background-size: cover;
    border-radius: 4px;
    position: relative;
    z-index: 0;
    overflow: hidden;
}
.menu-list .menu-item:after {
    content: '';
    background: rgba(254, 254, 254, 0);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254, 254, 254, 0)), color-stop(100%, rgba(0, 0, 0, 0.65)));
    background: linear-gradient(to bottom, rgba(254, 254, 254, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#000000', GradientType=0 );
    position: absolute;
    height: 70%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.menu-list .menu-item .menu-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    color: #fff;
    padding: 24px;
}
.menu-list .menu-item .menu-text h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #F2F2F2;
    margin-bottom: 8px;
}
.menu-list .menu-item .menu-text .time {
    height: 24px;
}
.menu-list .menu-item .menu-text .time .time-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #E0E0E0;
}
.top-header {
    padding: 16px;
    display: flex;
}
.top-header span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #212627;
    margin-left: 16px;
}
.category-list {
    padding: 16px 8px;
    display: flex;
    flex-wrap: wrap;
}
.category-list .category-item {
    background: #FFFFFF;
    box-shadow: 0 4px 14px rgba(63, 73, 77, 0.1);
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
    width: 100%;
    margin-right: 8px;
    margin-left: 8px;
}
.category-list .category-item .category-img img {
    width: 100%;
}
.category-list .category-item .category-details {
    padding: 16px;
    display: flex;
    justify-content: space-between;
}
.category-list .category-item .category-details h4 {
    margin: 0;
    margin-right: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #212627;
}
.category-list .category-item .category-details h5 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    margin: 0;
    color: #545454;
}
.item-screen .back-btn {
    position: fixed;
    top: 16px;
    left: 16px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    box-shadow: 0 4px 14px rgba(63, 73, 77, 0.25);
}
.item-screen .back-btn img {
    margin-right: 2px;
}
.item-screen .item-image {
    max-height: 300px;
    overflow: hidden;
}
.item-screen .item-image img {
    width: 100%;
}
.item-screen .item-details {
    max-width: 420px;
    margin: auto;
    padding: 16px;
}
.item-screen .item-details h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #212627;
    margin-bottom: 16px;
}
.item-screen .item-details h4.price {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #545454;
}
.item-screen .item-details .ingredients-list-container {
    margin-top: 32px;
}
.item-screen .item-details .ingredients-list-container h4 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #212627;
    margin: 0 0 24px;
}
.item-screen .item-details .ingredients-list-container .ingredients-list {
    display: flex;
    flex-wrap: wrap;
}
.item-screen .item-details .ingredients-list-container .ingredients-list .ingredients-item {
    width: 25%;
    text-align: center;
}
.item-screen .item-details .ingredients-list-container .ingredients-list .ingredients-item img {
    max-width: 40px;
    width: 100%;
    margin-bottom: 16px;
}
.item-screen .item-details .ingredients-list-container .ingredients-list .ingredients-item p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #545454;
    margin-bottom: 0;
}
.item-screen .item-details .details-item {
    margin-top: 32px;
}
.item-screen .item-details .details-item h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #212627;
    margin: 0 0 16px;
}
.item-screen .item-details .details-item p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #545454;
}
@media screen and (min-width:767px){
    .menu-list .menu-item {
        width: calc(25% - 16px);
    }
    .category-list .category-item {
        width: calc(50% - 16px);
    }
}


.loading-indicator:before {
    content: "";
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: 'Loading...';
    font-family: 'Montserrat', sans-serif;
    /* font-weight: 900; */
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color: white;
    text-align: center;
    font-size: 35px;
    -webkit-animation: fa-spinner 1s steps(8) infinite;
    animation: fa-spinner 1s steps(8) infinite;
  }

  .category-img {
    height: auto;
    max-height: 350px;
    overflow: hidden;
}

.rest-logo {
    max-width: 100px;
}

.menu-flags {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999;
    padding: 0;
}
.menu-flags .flag-select__btn {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
}
.menu-flags .flag-select__btn .flag-select__option .flag-select__option__label {
    display: none;
}
.menu-flags .flag-select__btn .flag-select__option .flag-select__option__icon {
    top: 0;
}
.menu-flags .flag-select__btn:focus {
    outline: 0;
}
.menu-flags .flag-select__options {
    right: 0;
    padding: 0;
    border: none;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
    max-height: 200px;
}
.menu-flags .flag-select__options .flag-select__option__icon {
    top: 0;
}
.menu-flags .flag-select__option.has-label {
    padding: 5px 28px 5px 15px;
    text-transform: capitalize;
    position: relative;
}
.menu-flags .flag-select__option.has-label.active {
    background-color: #FEB62C;
    color: #fff;
}
.menu-flags .flag-select__option.has-label.active::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBvbHlsaW5lIHN0cm9rZS13aWR0aD0nMS41JyBzdHJva2U9JyNmZmZmZmYnIGZpbGw9J25vbmUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgcG9pbnRzPScxLDkgNSwxMyAxNSwzICcvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

